import React, { useState } from "react";
import Slider from "react-slick";
import ImageTitle from "../assets/image/des_title_main.png";
import Image1 from "../assets/image/4-8897-6086.png";
import Image2 from "../assets/image/bgnhantin-9904.png";
import ph1 from "../assets/image/ph1.jpg";
import ph2 from "../assets/image/ph2.jpeg";
import ph3 from "../assets/image/ph3.jpeg";
import ph4 from "../assets/image/ph4.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ParentFeedback = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: "0px",
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  return (
    <div className="ykien-index" data-aos="fade-up" data-aos-duration="1000">
      <div className="wrap-content position-relative">
        <div className="title-main">
          <p>Phụ huynh nói gì về</p>
          <span>mầm non an thơ</span>
          <i>
            <img src={ImageTitle} alt="Title Decoration" />
          </i>
        </div>
        <div>
          <Slider {...settings} className="ykien-contain">
            {[
              {
                imgSrc: ph1,

                name: "Mẹ Su",
                feedback:
                  "Cô giáo mầm non là hình mẫu tuyệt vời, với sự kiên nhẫn và tình yêu thương vô bờ, đã truyền cảm hứng và giúp các bé yêu thích việc học mỗi ngày.",
              },
              {
                imgSrc: ph4,
                name: "Bố Bảo Ngọc",
                feedback:
                  "Cô giáo mầm non không chỉ dạy kiến thức mà còn là người bạn đáng tin cậy, chăm sóc tận tình và luôn mang đến môi trường học tập vui tươi cho các bé",
              },
              {
                imgSrc: ph2,
                name: "Mẹ Sunny",
                feedback:
                  "Nhà trường có môi trường học tập thân thiện, cơ sở vật chất hiện đại, và đội ngũ giáo viên nhiệt huyết, luôn quan tâm và hỗ trợ sự phát triển của các bé.",
              },
              {
                imgSrc: ph4,
                name: "Bố Bảo Ngọc",
                feedback:
                  "Cô giáo mầm non không chỉ dạy kiến thức mà còn là người bạn đáng tin cậy, chăm sóc tận tình và luôn mang đến môi trường học tập vui tươi cho các bé",
              },
            ].map((slide, index) => (
              <div
                key={index}
                className={`ykien-pic--outside ${
                  currentSlide === index ? "slick-center" : ""
                }`}
              >
                <div className="tranfrom-scale">
                  <p className="ykien-pic">
                    <img src={slide.imgSrc} alt={slide.name} />
                  </p>
                </div>
                {currentSlide === index && (
                  <div className="ykien-info">
                    <h3 className="text-split">{slide.name}</h3>
                    <span className="text-split">{slide.feedback}</span>
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
        <div className="des-ykien--after">
          <img src={Image1} alt="MẦM NON AN THƠ" />
        </div>
        <div className="des-ykien--before">
          <img src={Image2} alt="MẦM NON AN THƠ" />
        </div>
      </div>
    </div>
  );
};

export default ParentFeedback;
