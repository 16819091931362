import React from "react";
import IconPhone from "../assets/image/phone.png";
import { Col } from "react-bootstrap";
const Header = () => {
  return (
    <div className="head">
      <div className="wrap-content row justify-content-between align-items-center">
        {/* <p className="slogan-head mb-0 col-lg-6 col-12">
          <marquee>Chào mừng bạn đến với Mầm non An Thơ</marquee>
        </p> */}
        <Col>
          <marquee>Chào mừng bạn đến với Mầm non An Thơ</marquee>
        </Col>
        <Col className="hotline-head mb-0 col-6 d-none d-lg-flex d-md-flex justify-content-end">
          <span className="title-hotline">
            <img
              src={IconPhone}
              alt="Hotline"
              style={{ margin: "0 3px 3px 0" }}
            />
            Hotline:
          </span>{" "}
          <span className="number-hotline ms-2">0902 722 608</span>
        </Col>
        {/* <p className="hotline-head mb-0 col-6 d-none d-lg-flex d-md-flex ">
          <span className="title-hotline">
            <img src={IconPhone} alt="Hotline" />
            Hotline:
          </span>
          <span className="number-hotline">0902 722 608</span>
        </p> */}
      </div>
    </div>
  );
};

export default Header;
