import React, { useEffect } from "react";

import Menu from "../components/Menu";
import Slideshow from "../components/SlideShow";
import Wrap from "../components/Wrap";
import Advantages from "../components/Advantage";
import ParentFeedback from "../components/Parrent";
import Study from "../components/Study";
import LibraryComponent from "../components/LibraryImage";
import FormComponent from "../components/FormComponent";
import BlogComponent from "../components/Blog";
import Train from "../components/Train";
import Hethong from "../components/HeThongComponent";

const MainPage = () => {
  useEffect(() => {
    document.title = "Trang chủ";
  }, []);
  return (
    <div>
      <Slideshow />
      <Wrap />
      <Advantages />
      <Study />
      <ParentFeedback />
      <LibraryComponent />
      <FormComponent />
      <Hethong />
      <BlogComponent />
      <Train />
    </div>
  );
};

export default MainPage;
