import React, { useEffect } from "react";

import Blog from "../components/Blog";

const TinTuc = () => {
  useEffect(() => {
    document.title = "Tin tức";
  }, []);
  return (
    <div className="mt-4">
      <Blog />
    </div>
  );
};

export default TinTuc;
