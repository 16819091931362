import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";

import axios from "axios";
import PostModal from "./PostModal"; // Import component mới tạo
import SolutionEditModal from "./PostEditModal";
const PostTable = () => {
  const [posts, setPosts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalType, setModalType] = useState("add");
  const [selectedSolution, setSelectedSolution] = useState(0);
  const [currentPost, setCurrentPost] = useState({
    id: null,
    categoryId: 61,
    postSlug: "",
    postKeyword: "",
    postTitleVi: "",
    postTitleEn: "",
    postDescVi: "",
    postDescEn: "",
    postContentVi: "",
    postContentEn: "",
    postImage: "",
  });

  useEffect(() => {
    getPost();
  }, []);

  const getPost = () => {
    axios
      .get("/post/show")
      .then((res) => {
        setPosts(res.data.posts || []);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleAddPost = () => {
    setModalType("add");
    setCurrentPost({
      id: null,
      categoryId: "",
      postSlug: "",
      postKeyword: "",
      postTitleVi: "",
      postTitleEn: "",
      postDescVi: "",
      postDescEn: "",
      postContentVi: "",
      postContentEn: "",
      postImage: "",
    });

    setShowModal(true);
  };

  const handleDeletePost = (id) => {
    swal({
      title: `Bạn muốn xóa giải pháp này`,
      text: ` Lưu ý: Hành động không thể khôi phục lại`,
      icon: "warning",
      buttons: ["Đóng", "Xóa"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        axios
          .delete(`/post/destroy`, { params: { id: id } })
          .then((res) => {
            if (res.data.errCode === 0) {
              toast(res.data.message, {
                type: "success",
                autoClose: 1000,
              });
              getPost();
            } else {
              toast(res.data.message, {
                type: "error",
                autoClose: 1000,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  const handleModalSubmit = () => {
    if (modalType === "add") {
      createPost();
    } else if (modalType === "edit") {
      updatePost();
    }
  };

  const createPost = () => {
    axios
      .post("/post/create", currentPost)
      .then((res) => {
        getPost();
        toast("Bài viết đã được thêm thành công!", { type: "success" });
        setShowModal(false);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const updatePost = () => {
    axios
      .put(`/post/update`, currentPost)
      .then((res) => {
        getPost();
        toast(res.data.message || "Bài viết đã được cập nhật thành công!", {
          type: "success",
        });
        setShowModal(false);
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };

  const handleCloseAddModal = (isReload) => {
    setShowModal(false);
    if (isReload) {
      getPost();
    }
  };
  const handleShowAddModal = () => {
    setShowModal(true);
    setSelectedSolution();
  };

  const handleCloseEditModal = (isReload) => {
    setShowEditModal(false);
    if (isReload) {
      getPost();
    }
  };
  const handleShowEditModal = (solutionId) => {
    setShowEditModal(true);
    setSelectedSolution(solutionId);
  };
  return (
    <>
      <Button
        variant="primary"
        // onClick={handleAddPost}
        onClick={() => handleShowAddModal()}
        className="mb-2 float-end"
      >
        Thêm bài viết
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr className="text-center">
            <th>STT</th>
            <th>Slug</th>
            {/* <th>Từ khóa</th> */}
            <th>Tiêu đề </th>

            <th>Mô tả </th>

            {/* <th>Nội dung </th> */}

            <th>#</th>
          </tr>
        </thead>
        <tbody>
          {posts.map((post, index) => (
            <tr key={post.id}>
              <td>{index + 1}</td>
              <td>
                <span className="text-ellipsis-2-lines ">{post.postSlug}</span>
              </td>
              <td>
                <span className="text-ellipsis-2-lines ">
                  {post.postTitleVi}
                </span>
              </td>

              <td>
                <span className="text-ellipsis-2-lines ">
                  {post.postDescVi}
                </span>
              </td>
              {/* <td>{post.postContentVi}</td> */}
              <td style={{ whiteSpace: "nowrap" }}>
                <Button
                  variant="warning"
                  onClick={() => handleShowEditModal(post.id)}
                  className="me-2"
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDeletePost(post.id)}
                  disabled={post.id == 28 || post.id == 29 || post.id == 30}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {showModal ? (
        <PostModal
          handleCloseModal={handleCloseAddModal}
          showModal={showModal}
        />
      ) : null}
      {showEditModal ? (
        <SolutionEditModal
          handleCloseModal={handleCloseEditModal}
          showModal={showEditModal}
          selectedSolution={selectedSolution}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PostTable;
