import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import ImageTitle from "../assets/image/des_title_main.png";

import TextEditor from "../components/TextEditor";
const TuyenSinh = () => {
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Tuyển sinh";
    getDetailNew();
  }, []);

  const getDetailNew = () => {
    setIsLoading(true);
    axios
      .get(`/post/list`, {
        params: {
          id: Number(process.env.REACT_APP_TUYENSINH_POST || 29),
        },
      })
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setContent(res.data.post.postContentVi);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container className="container-wrapper my-5">
      <div className="title-layout mb-3"></div>
      <div className="title-main">
        <span>Tuyển Sinh</span>
        <i>
          <img src={ImageTitle} alt="Title" />
        </i>
      </div>
      <div>
        {isLoading ? (
          //   <Loading />
          <p className="text-center">Chưa có dữ liệu</p>
        ) : content ? (
          <TextEditor
            disable
            hideToolbar={true}
            setContents={content}
            onChange={() => {}}
            style={{ border: "none" }}
          />
        ) : (
          ""
        )}
      </div>
    </Container>
  );
};

export default TuyenSinh;
