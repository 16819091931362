import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link
import Mmenu from "mmenu-js";
import "mmenu-js/dist/mmenu.css";
import Logo from "../assets/image/logo.png";
import { Button } from "react-bootstrap";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const [isShow, setIsShow] = useState(false);
  const showMenu = () => {
    console.log(">>>>");

    setIsShow(!isShow);
  };
  useEffect(() => {
    new Mmenu(document.querySelector("#my-menu"));
  }, []);

  return (
    <div className={`w-menu ${isMenuOpen ? "menu-open" : ""}`}>
      <div className="menu menu-index">
        <div className="wrap-content">
          <ul className="menu-main">
            <li>
              <Link className="logo-head" to="/" title="MẦM NON AN THƠ">
                <img
                  onError={(e) => (e.target.src = { Logo })}
                  src={Logo}
                  alt="Logo"
                />
              </Link>
            </li>
            <li>
              <Link className="transition" to="/gioi-thieu" title="Giới thiệu">
                Giới thiệu
              </Link>
            </li>
            <li>
              <Link
                className="transition"
                to="/chuong-trinh-hoc"
                title="Chương trình học"
              >
                Chương trình học
              </Link>
            </li>
            <li>
              <Link
                className="transition"
                to="/moi-truong-hoc"
                title=" Môi trường học"
              >
                Môi trường học
              </Link>
            </li>
            <li>
              <Link className="transition" to="/tuyen-sinh" title="Tuyển sinh">
                Tuyển sinh
              </Link>
            </li>
            <li>
              <Link className="transition" to="/tin-tuc" title="Tin tức">
                Tin tức
              </Link>
            </li>
            <li>
              <Link className="transition" to="/lien-he" title="Liên hệ">
                Liên hệ
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="menu-res">
        <div className="menu-bar-res d-flex align-items-center justify-content-between">
          <a
            id="hamburger"
            href="#my-menu"
            title="Menu"
            onClick={() => showMenu()}
          >
            <span></span>
          </a>

          <Link className="logo-res" to="/">
            <img src={Logo} alt="logo" title="logo" />
          </Link>
        </div>

        <nav id="my-menu" style={{ display: isShow ? "block" : "none" }}>
          <ul className="menu-main">
            <li>
              <Link className="logo-head" to="/" title="MẦM NON AN THƠ">
                <img
                  onError={(e) => (e.target.src = { Logo })}
                  src={Logo}
                  alt="Logo"
                />
              </Link>{" "}
              <Link
                className="d-flex justify-content-end"
                title="MẦM NON AN THƠ"
                onClick={() => console.log()}
              >
                X
              </Link>{" "}
            </li>

            <li>
              <Link className="transition" to="/gioi-thieu" title="Giới thiệu">
                Giới thiệu
              </Link>
            </li>
            <li>
              <Link
                className="transition"
                to="/chuong-trinh-hoc"
                title="Chương trình học"
              >
                Chương trình học
              </Link>
            </li>
            <li>
              <Link
                className="transition"
                to="/moi-truong-hoc"
                title=" Môi trường học"
              >
                Môi trường học
              </Link>
            </li>
            <li>
              <Link className="transition" to="/tuyen-sinh" title="Tuyển sinh">
                Tuyển sinh
              </Link>
            </li>
            <li>
              <Link className="transition" to="/tin-tuc" title="Tin tức">
                Tin tức
              </Link>
            </li>
            <li>
              <Link className="transition" to="/lien-he" title="Liên hệ">
                Liên hệ
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Menu;
