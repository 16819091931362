import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  ListGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./dashboard.css";
import swal from "sweetalert";
import { toast } from "react-toastify";
import PostTable from "./Post.js";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 767);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  useEffect(() => {
    document.title = "Trang quản lý";
  }, []);
  const Logout = () => {
    swal({
      title: `Bạn muốn đăng xuất`,
      icon: "warning",
      buttons: ["Đóng", "Đăng xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        navigate("/");
        toast("Đăng xuất thành công", {
          type: "success",
          autoClose: 2000,
        });
        localStorage.clear();
      }
    });
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <Col
            md={isCollapsed ? 1 : 3}
            lg={isCollapsed ? 1 : 2}
            className={`bg-dark text-white min-vh-100 p-0 sidebar ${
              isCollapsed ? "collapsed" : ""
            }`}
          >
            <Navbar
              bg="dark"
              variant="dark"
              className="flex-column align-items-start p-3"
            >
              <Button variant="dark" onClick={toggleSidebar} className="mb-3">
                {isCollapsed ? "☰" : "✖"}
              </Button>
              <Navbar.Brand
                as={Link}
                to="/"
                className={`${isCollapsed ? "d-none" : ""}`}
              >
                QUẢN LÝ
              </Navbar.Brand>
              <Nav className="flex-column w-100">
                <ListGroup variant="flush">
                  <ListGroup.Item
                    action
                    as={Link}
                    // to="/manage-posts"
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      ✍ Quản lý bài viết
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>✍</span>
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    onClick={() => Logout()}
                    className="bg-dark text-white d-flex align-items-center"
                  >
                    <span className={`mr-2 ${isCollapsed ? "d-none" : ""}`}>
                      Đăng xuất
                    </span>
                    <span className={isCollapsed ? "" : "d-none"}>⎋</span>
                  </ListGroup.Item>
                </ListGroup>
              </Nav>
            </Navbar>
          </Col>

          {/* Content */}
          <Col
            md={isCollapsed ? 11 : 9}
            lg={isCollapsed ? 11 : 10}
            className={`p-4 content ${isCollapsed ? "collapsed" : ""}`}
            style={{ marginLeft: isCollapsed ? "100px" : "16%" }}
          >
            <h2>Quản lý bài viết</h2>
            <PostTable />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
