import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Dashboard from "../components/admin/Dashboard";

const AdminPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token_antho");
    if (!token) {
      navigate("/login"); // Chuyển hướng đến trang login nếu không có token
    }
  }, [navigate]);

  return (
    <div>
      <Dashboard />
    </div>
  );
};

export default AdminPage;
