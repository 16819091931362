import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bg1 from "../assets/image/Banner_1.jpg";
import bg2 from "../assets/image/Banner_2.jpg";
import bg3 from "../assets/image/thiet-ke-chua-co-ten-1-86820.png";
const slideData = [
  {
    id: 1,
    imageUrl: bg1,
    link: "",
    alt: "Hình 1",
  },
  {
    id: 2,
    imageUrl: bg2,
    link: "",
    alt: "Hình 2",
  },
];
const tieuchiData = [
  {
    id: 1,
    title:
      "Các chương trình ưu đãi học phí dành cho học sinh đăng ký ghi danh sớm",
    link: "",
  },
  {
    id: 2,
    title: "Đội ngũ giáo viên tận tâm, giàu kinh nghiệm.",
    link: "",
  },
  {
    id: 3,
    title: "Đồ dùng đồ chơi hiện đại, luôn được quan tâm bổ sung và thay mới",
    link: "",
  },
  {
    id: 4,
    title: "Phòng học rộng rãi thoáng mát.",
    link: "",
  },
  {
    id: 5,
    title:
      "Phương pháp giảng dạy tích hợp, linh hoạt kích thích nhu cầu tự khám phá, trải nghiệm và rút ra bài học thực tiễn.",
    link: "",
  },
  {
    id: 6,
    title:
      "Các hoạt động ngoại khoá là một phần quan trọng trong chương trình học tập và rèn luyện của con trẻ tại trường.",
    link: "",
  },
];

const Slideshow = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    fade: true,
    // centerMode: true,
    arrows: true,
  };
  const settingTieuchi = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };
  return (
    <>
      <div>
        <Slider {...settings} className="slideshow ">
          {slideData.map((slide) => (
            <div key={slide.id} className="slideshow-item">
              <img
                className="w-100 img-slideshow"
                loading="lazy"
                src={slide.imageUrl}
                alt={slide.alt}
                title={slide.alt}
              />
            </div>
          ))}
        </Slider>
      </div>
      {/* <div class="control-slideshow control-owl transition"></div> */}

      <Slider {...settingTieuchi} className="box-tieuchi-outside">
        {tieuchiData.map((item) => (
          <div key={item.id} className="tieuchi-item">
            <h3>
              <a href={item.link} className="text-decoration-none text-split">
                {item.title}
              </a>
            </h3>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Slideshow;
