import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom"; // Import useParams
import ImageTitle from "../assets/image/des_title_main.png";
import TextEditor from "../components/TextEditor";

const BlogDetail = () => {
  const [content, setContent] = useState("");
  const [post, setPost] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams(); // Lấy slug từ URL

  useEffect(() => {
    document.title = "Giới thiệu";
    getDetailNew();
  }, [slug]); // Thêm slug vào dependency array

  const getDetailNew = () => {
    setIsLoading(true);

    axios
      .get(`/post/list?postSlug=${slug}`) // Sử dụng slug trong URL
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setContent(res.data.post.postContentVi);
          setPost(res.data.post);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        toast("Có lỗi xảy ra, vui lòng thử lại sau", {
          type: "error",
          autoClose: 2000,
        });
      });
  };

  return (
    <Container className="container-wrapper my-5">
      <div className="title-layout mb-3"></div>
      <div className="title-main">
        <span>{post.postTitleVi}</span>
        <i>
          <img src={ImageTitle} alt="Title" />
        </i>
      </div>
      <div>
        {isLoading ? (
          <p className="text-center">Đang tải...</p>
        ) : content ? (
          <TextEditor
            disable
            hideToolbar={true}
            setContents={content}
            onChange={() => {}}
            style={{ border: "none" }}
          />
        ) : (
          <p className="text-center">Chưa có dữ liệu</p>
        )}
      </div>
    </Container>
  );
};

export default BlogDetail;
