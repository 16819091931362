import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import yup from "../../../utils/yupGlobal";
import yup from "../../utils/yupGlobal";
import { toast } from "react-toastify";

import { Button, Form, Modal } from "react-bootstrap";
import TextEditor from "../TextEditor";
// import TextEditor from "../../../components/TextEditor";
import convertToSlug from "../../utils/slugFormatter";

const editSolutionFormItems = [
  {
    id: 1,
    placeholder: "Tiêu đề",
    name: "title",
  },
  {
    id: 2,
    placeholder: "Mô tả",
    name: "description",
  },
];

const SolutionEditModal = ({
  handleCloseModal,
  showModal,
  selectedSolution,
}) => {
  const [slug, setSlug] = useState("");
  const [solutionImage, setSolutionImage] = useState("");
  const [content, setContent] = useState("");

  const [selectedTopic, setSelectedTopic] = useState(0);
  const [topicOptions, setTopicsOptions] = useState([]);

  const [isSendForm, setIsSendForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState({
    postSlug: undefined,
  });

  const {
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(Schema),
  });

  useEffect(() => {
    getDetailSolution(selectedSolution);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Api

  const getDetailSolution = (solutionId) => {
    setIsLoading(true);
    axios
      .get(`/post/show`, {
        params: {
          id: solutionId,
        },
      })
      .then(async (res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setSelectedTopic(res.data.post.categoryId);
          reset({
            title: res.data.post.postTitleVi,
            description: res.data.post.postDescVi,
          });
          setContent(res.data.post.postContentVi);
          setSlug(res.data.post.postSlug);

          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}${res.data.post.imgURL}`
          );
          const blob = await response.blob();
          const file = new File([blob], "image.jpg", {
            type: blob.type,
          });
          setSolutionImage(file);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSolution = async (data) => {
    setIsSendForm(true);

    var bodyFormData = new FormData();

    bodyFormData.append("id", selectedSolution);
    bodyFormData.append("postTitleVi", data.title);
    bodyFormData.append("postDescVi", data.description);
    bodyFormData.append("postContentVi", content);
    bodyFormData.append("postSlug", slug);
    bodyFormData.append("isStatus", "S1");
    bodyFormData.append("postImage", solutionImage);

    await axios({
      method: "put",
      url: "post/update",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setIsSendForm(false);

        if (res.data.errCode === 0) {
          toast(res.data.message, {
            type: "success",
            autoClose: 1000,
          });
          handleCloseModal(true);
        } else {
          setErrMessage(res.data.message);
          toast("Có lỗi xảy ra, vui lòng kiểm tra lại thông tin", {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Api

  const handleUploadImage = (event) => {
    try {
      if (
        !event.target.files[0].type.match(/image.*/) ||
        event.target.files[0].size > 2097152
      ) {
        toast("File ảnh phải nhỏ hơn bằng 2MB", {
          type: "error",
          autoClose: 1000,
        });

        return;
      }

      setSolutionImage(event.target.files[0]);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => handleCloseModal(false)}
      backdrop="static"
    >
      <Modal.Header closeButton={!isSendForm}>
        <Modal.Title>Cập nhật bài viết</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          id="edit-solution-hook-form"
          onSubmit={handleSubmit((data) => {
            if (!solutionImage) {
              toast("Vui lòng chọn file ảnh", {
                type: "error",
                autoClose: 1000,
              });
              return;
            }

            updateSolution(data);
          })}
          className="mb-5"
        >
          {editSolutionFormItems?.map((formItem) => {
            if (formItem.id === 2) {
              return (
                <div key={formItem.id} className="mb-3">
                  <Form.Control
                    placeholder={formItem.placeholder}
                    name={formItem.name}
                    {...register(formItem.name)}
                    as={`textarea`}
                    rows={5}
                  />

                  {errors[formItem.name] && (
                    <p
                      style={{
                        fontSize: 13,
                        color: "red",
                        marginTop: 6,
                      }}
                    >
                      {errors[formItem.name].message}
                    </p>
                  )}
                </div>
              );
            }
            return (
              <div key={formItem.id} className="mb-3">
                <Form.Floating>
                  <Form.Control
                    type="text"
                    placeholder={formItem.placeholder}
                    name={formItem.name}
                    {...register(formItem.name, {
                      onChange: () => {
                        setSlug(convertToSlug(getValues("title")));
                      },
                    })}
                  />

                  <Form.Label>
                    {formItem.placeholder}
                    <i className="text-danger">*</i>
                  </Form.Label>
                </Form.Floating>

                {errors[formItem.name] && (
                  <p
                    style={{
                      fontSize: 13,
                      color: "red",
                      marginTop: 6,
                    }}
                  >
                    {errors[formItem.name].message}
                  </p>
                )}
              </div>
            );
          })}

          {/* Content */}
          <div className="mb-3">
            <Form.Label>Nội dung</Form.Label>
            <TextEditor
              setContents={content}
              onChange={(value) => setContent(value)}
              placeholder={"Nội dung"}
              style={{ border: "1px solid #dadada", padding: 16 }}
            />
          </div>

          <div className="mb-3">
            <Form.Floating>
              <Form.Control
                type="text"
                placeholder="Slug"
                name="slug"
                disabled
                value={slug}
              />

              <Form.Label>
                Slug
                <i className="text-danger">*</i>
              </Form.Label>
            </Form.Floating>
            {errMessage.solutionSlug && (
              <p
                style={{
                  fontSize: 13,
                  color: "red",
                  marginTop: 6,
                }}
              >
                {errMessage.solutionSlug}
              </p>
            )}
          </div>
        </Form>

        {/* Image */}
        <div>
          {solutionImage ? (
            <div className="d-flex justify-content-center">
              <div
                style={{
                  aspectRatio: "900/600",
                  backgroundImage: `url("${URL.createObjectURL(
                    solutionImage
                  )}")`,
                  backgroundSize: "cover",
                  width: "70%",
                }}
                className="image-default position-relative mb-3"
              />
            </div>
          ) : (
            ""
          )}

          <input
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(e) => handleUploadImage(e)}
            required
          />
          <div>
            <div className="d-inline fw-bold">Note: </div>
            Chọn ảnh với tỉ lệ 500:400 để được chất lượng tốt nhất.
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          type="button"
          variant="secondary"
          onClick={() => handleCloseModal(false)}
          disabled={isSendForm}
        >
          Đóng
        </Button>
        <Button
          type="submit"
          form="edit-solution-hook-form"
          variant="primary"
          disabled={isSendForm || isLoading}
        >
          Cập nhật
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Schema = yup.object().shape({
  title: yup
    .string()
    .required("Không được bỏ trống")
    .test(
      "len",
      "Tiêu đề không dài quá 255 kí tự",
      (val) => val.length >= 0 && val.length <= 255
    ),
  description: yup
    .string()
    .required("Không được bỏ trống")
    .test("len", "Mô tả không dài quá 255 kí tự", (val) => val.length <= 255),
});

export default SolutionEditModal;
