import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageTitle from "../assets/image/des_title_main.png";
import Image1 from "../assets/image/1-5623-6984.png";
import lt1 from "../assets/image/lt1.png";
import lt2 from "../assets/image/lt2.png";
import lt3 from "../assets/image/lt3.png";
import lt4 from "../assets/image/lt4.png";
import lt5 from "../assets/image/lt5.png";
import lt6 from "../assets/image/lt6.png";

const Advantage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplaySpeed: 3000, // Thời gian cuộn tự động
    arrows: false,
    adaptiveHeight: true, // Điều chỉnh chiều cao của slide
    rows: 2, // Hiển thị 2 hàng
    responsive: [
      {
        breakpoint: 1024, // Đối với màn hình rộng hơn 1024px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          rows: 1, // Hiển thị 1 hàng trên màn hình rộng vừa
        },
      },
      {
        breakpoint: 768, // Đối với màn hình rộng hơn 768px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2, // Hiển thị 1 hàng trên màn hình nhỏ hơn
          adaptiveHeight: true, // Vẫn giữ adaptive height cho slide
        },
      },
      {
        breakpoint: 480, // Đối với màn hình rộng hơn 480px
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 2, // Hiển thị 1 hàng trên màn hình nhỏ hơn
          adaptiveHeight: true,
        },
      },
    ],
  };
  const loitheData = [
    {
      id: 1,
      title: "Đội ngũ giáo viên tận tâm, giàu kinh nghiệm.",
      image: lt3,
      alt: "Đội ngũ giáo viên tận tâm, giàu kinh nghiệm.",
    },
    {
      id: 2,
      title: "Đồ dùng đồ chơi hiện đại, luôn được quan tâm bổ sung và thay mới",
      image: lt5,
      alt: "Đồ dùng đồ chơi hiện đại, luôn được quan tâm bổ sung và thay mới",
    },
    {
      id: 3,
      title: "Phòng học rộng rãi thoáng mát.",
      image: lt4,
      alt: "Phòng học rộng rãi thoáng mát.",
    },
    {
      id: 4,
      title:
        "Phương pháp giảng dạy tích hợp, linh hoạt kích thích nhu cầu tự khám phá, trải nghiệm và rút ra bài học thực tiễn.",
      image: lt1,
      alt: "Phương pháp giảng dạy tích hợp, linh hoạt kích thích nhu cầu tự khám phá, trải nghiệm và rút ra bài học thực tiễn.",
    },
    {
      id: 5,
      title:
        "Các hoạt động ngoại khoá là một phần quan trọng trong chương trình học tập và rèn luyện của con trẻ tại trường.",
      image: lt2,
      alt: "Các hoạt động ngoại khoá là một phần quan trọng trong chương trình học tập và rèn luyện của con trẻ tại trường.",
    },
    {
      id: 6,
      title:
        "Các chương trình ưu đãi học phí dành cho học sinh đăng ký ghi danh sớm.Hỗ trợ giảm học phí khi có anh chị em ruột cùng đăng ký theo học tại trường. Chia thành nhiều đợt thanh toán khác nhau, tạo điều kiện thuận lợi cho nhiều phụ huynh.",
      image: lt6,
      alt: "Các chương trình ưu đãi học phí dành cho học sinh đăng ký ghi danh sớm.Hỗ trợ giảm học phí khi có anh chị em ruột cùng đăng ký theo học tại trường. Chia thành nhiều đợt thanh toán khác nhau, tạo điều kiện thuận lợi cho nhiều phụ huynh.",
    },
  ];

  return (
    <div className="loithe-index " data-aos="fade-up" data-aos-duration="1000">
      <div className="wrap-content position-relative">
        <div className="title-main">
          <span>Lợi thế Mầm Non An Thơ</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="loithe-contain">
          <Slider {...settings}>
            {loitheData.map((item) => (
              <div key={item.id} className="loithe-col">
                <div className="loithe">
                  <div className="loithe-pic">
                    <p className="mb-0">
                      <img
                        style={{ display: "unset" }}
                        src={item.image}
                        alt={item.alt}
                      />
                    </p>
                  </div>
                  <div className="loithe-info">
                    <h3 className="text-split">{item.title}</h3>
                    <span className="text-split"></span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="des-loithe--after">
          <img src={Image1} alt="MẦM NON AN THƠ" />
        </div>
      </div>
    </div>
  );
};

export default Advantage;
