import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

const NotFound = () => {
  return (
    <Container className="d-flex align-items-center justify-content-center min-vh-100">
      <Row className="text-center">
        <Col>
          <h1 className="display-1">404</h1>
          <h2 className="display-4">Không tìm thấy trang</h2>
          <p className="lead">
            Trang bạn đang tìm không tồn tại hoặc đã bị xóa.
          </p>
          <Button href="/" variant="primary">
            Quay lại trang chính
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
