import React, { useEffect } from "react";

import Study from "../components/Study";
import FormComponent from "../components/FormComponent";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Liên hệ";
  }, []);
  return <FormComponent />;
};

export default ContactPage;
