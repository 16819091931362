import React from "react";
import Slider from "react-slick";
import ImageTitle from "../assets/image/des_title_main.png";
import image1 from "../assets/image/Hệ thống cơ sở_1.jpg";
import image2 from "../assets/image/Hệ thống cơ sở_2.jpg";

const Hethong = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    // autoplay: true,
    autoplaySpeed: 3500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 80,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 60,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 40,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
    ],
  };

  const hethongData = [
    {
      id: 1,
      image: image1,
      alt: "Cơ Sở 1",
      link: "https://maps.app.goo.gl/rsDQf7zzwoLy1a6ZA",
      address: "KV Bình Hoà A, phường Phước Thới, quận Ô Môn, TP.Cần Thơ",
    },
    {
      id: 2,
      image: image2,

      alt: "Cơ Sở 2",
      link: "https://maps.app.goo.gl/rsDQf7zzwoLy1a6ZA",
      address: "KV Bình Hoà A, phường Phước Thới, quận Ô Môn, TP.Cần Thơ",
    },
  ];

  return (
    <div
      className="hethong-index"
      //  data-aos="fade-up" data-aos-duration="1000"
    >
      <div className="wrap-content">
        <div className="title-main">
          <span>hệ thống cơ sở</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="hethong-contain">
          <Slider {...settings} className="slick-slider">
            {hethongData.map((item) => (
              <div key={item.id} className="hethong-item">
                <div className="">
                  <div className="hethong-pic">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="scale-img"
                      href={item.link}
                    >
                      <img className="w-100" src={item.image} alt={item.alt} />
                    </a>
                  </div>
                  <div className="hethong-info">
                    <h3>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.link}
                        className="text-split text-decoration-none"
                      >
                        {item.alt}
                      </a>
                    </h3>
                    <span className="text-split">{item.address}</span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.link}
                      className="text-split text-decoration-none spacing-letter"
                    >
                      <i className="fa-solid fa-location-dot"></i> Xem bản đồ
                      tại đây
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Hethong;
