import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import ClientPage from "./pages/ClientPage";
import MainPage from "./pages/MainMenu";
import IntroPage from "./pages/IntroPage";
import BlogDetail from "./pages/BlogDetail";
import "mmenu-js/dist/mmenu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import NotFound from "./pages/404";
import StudyPage from "./pages/StudyPage";
import ContactPage from "./pages/ContactPage";
import Introduction from "./pages/IntroPage";
import TuyenSinh from "./pages/TuyenSinh";
import MoiTruong from "./pages/MoiTruong";
import TinTuc from "./pages/TinTuc";
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}`;

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "*/*";
axios.defaults.headers.post["Access-Control-Allow-Origin"] =
  process.env.REACT_APP_CLIENT_URL || "*";
axios.defaults.headers["Authorization"] = localStorage.getItem("token_antho")
  ? "Bearer " + localStorage.getItem("token_antho")
  : "";
axios.defaults.headers["Shop-Domain"] = process.env.REACT_APP_SHOP_DOMAIN_URL;
function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<ClientPage />}>
          <Route index element={<MainPage />} />
          <Route path="chuong-trinh-hoc" element={<StudyPage />} />
          <Route path="lien-he" element={<ContactPage />} />
          <Route path="gioi-thieu" element={<Introduction />} />

          <Route path="tuyen-sinh" element={<TuyenSinh />} />
          <Route path="moi-truong-hoc" element={<MoiTruong />} />
          <Route path="tin-tuc" element={<TinTuc />} />
          <Route path="tin-tuc/:slug" element={<BlogDetail />} />
        </Route>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
