import React from "react";
import Slider from "react-slick";
// import "./styles.css"; // Để thêm CSS tùy chỉnh
import ImageTitle from "../assets/image/des_title_main.png";
import Image1 from "../assets/image/png-1147.png";
import Image2 from "../assets/image/image-3-2001.png";
import Image3 from "../assets/image/image-4-7469.png";
import imageArrow from "../assets/image/arrows.png";
const chuongtrinhhocData = [
  {
    id: 1,
    title: "Chương Trình Học T2",
    image: Image1,
    alt: "Chương Trình Học T2",
    link: "",
    items: [
      "Kids Yoga",
      "English",
      "Morning Snack",
      "Music Hát và VĐTN",
      "English Reading circle",
      "Art craft",
      "Outdoor Activities",
      "Drop off/ Trả bé",
    ],
  },
  {
    id: 2,
    title: "Chương Trình Học T3",
    image: Image2,
    alt: "Chương Trình Học T3",
    link: "",
    items: [
      "Outdoor Activities",
      "English",
      "Morning Snack",
      "Montessori",
      "English/Math",
      "Pretend play",
      "Tiền tiểu học",
      "Drop off/ Trả bé",
    ],
  },
  {
    id: 3,
    title: "Chương Trình Học T4",
    image: Image3,
    alt: "Chương Trình Học T4",
    link: "",
    items: [
      "Outdoor Activities",
      "English",
      "Morning Snack",
      "Music/Zumba",
      "English Reading circle",
      "Skill",
      "Tiền tiểu học",
      "Drop off/ Trả bé",
    ],
  },
  {
    id: 4,
    title: "Chương Trình Học T5",
    image: Image2,
    alt: "Chương Trình Học T5",
    link: "",
    items: [
      "Montessori",
      "English / Science",
      "Morning Snack",
      "Math",
      "Tiền tiểu học",
      "Luch & Recess Ăn trưa & Nghỉ trưa",
      "English",
      "English/Language",
      "Sport Taekwondo",
      "Drop off/ Trả bé",
    ],
  },
  {
    id: 5,
    title: "Chương Trình Học T6",
    image: Image1,

    alt: "Chương Trình Học T6",
    link: "",
    items: [
      "Sport Taekwondo",
      "English/ Pretend play",
      "Morning Snack",
      "Music/ Zumba",
      "Language",
      "Luch & Recess Ăn trưa & Nghỉ trưa",
      "English",
      "Language",
      "Tiền tiểu học",
      "Drop off/ Trả bé",
    ],
  },
];

const Study = () => {
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  //   arrows: false,
  // };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <div
      className=" chuongtrinhhoc-index padding-main"
      // data-aos="fade-up"
      // data-aos-duration="1000"
    >
      <div className="wrap-content">
        <div className="title-main">
          <span>Chương trình học</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="chuongtrinhhoc-contain">
          <Slider {...settings} className="testClass">
            {chuongtrinhhocData.map((item) => (
              <div key={item.id} className="chuongtrinhhoc">
                <div className="">
                  <div className="chuongtrinhhoc-pic">
                    <p className="mb-0">
                      <img src={item.image} alt={item.alt} />
                    </p>
                  </div>
                  <div className="chuongtrinhhoc-info">
                    <h3>
                      <a href={item.link} className="text-split spacing-letter">
                        {item.title}
                      </a>
                    </h3>
                    <div>
                      <ul>
                        {item.items.map((i, index) => (
                          <li key={index}>
                            <span style={{ fontSize: "14px" }}>{i}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="btn-xemthem-cth">
                    <a
                      href={item.link}
                      className="text-decoration-none spacing-letter"
                    >
                      <i>
                        <img src={imageArrow} alt="View More" />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Study;
