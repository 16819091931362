import React from "react";
import Logo from "../assets/image/logo.png";
import ft1 from "../assets/image/inffo04.png";
import ft2 from "../assets/image/inffo04.png";
import ft3 from "../assets/image/inffo03.png";
import ft4 from "../assets/image/inffo02.png";
import ft5 from "../assets/image/inffo01.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="wrap-content">
        <div className="footer-logo mb-5">
          <p className="mb-0 text-center">
            <img
              onError={(e) => {
                e.target.src = { Logo };
              }}
              src={Logo}
              alt="Mầm non An Thơ"
            />
          </p>
        </div>
        <div className="footer-article">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="footer-news">
              <p className="name-company">Mầm non An Thơ</p>
              <div className="footer-info">
                <p>
                  <img alt="" height="30" src={ft1} width="30" />
                  Cơ sở 1: KV Bình Hoà A, phường Phước Thới, quận Ô Môn, TP.Cần
                  Thơ
                </p>
                <p>
                  <img alt="" height="30" src={ft2} width="30" />
                  Cơ sở 2: KV Bình Hoà A, phường Phước Thới, quận Ô Môn, TP.Cần
                  Thơ
                </p>
                <p>
                  <img alt="" height="31" src={ft3} width="30" />
                  0902 722 608
                </p>
                <p>
                  <img alt="" height="31" src={ft4} width="30" />
                  Email: antho@pgdomon.edu.vn
                </p>
                <p>
                  <img alt="" height="31" src={ft5} width="30" />
                  Website: https://mamnonantho.edu.vn
                </p>
                <p>
                  <marquee behavior="alternate">
                    <marquee width="150">
                      <a
                        href="https://thietkeweb.katec.vn/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong style={{ color: "#ff6600" }}>
                          Website Design by Katec
                        </strong>
                      </a>
                    </marquee>
                  </marquee>
                </p>
              </div>
            </div>
            <div className="footer-news">
              <p className="footer-title">Chính sách</p>
              <ul className="footer-ul d-flex flex-wrap justify-content-between">
                <li>
                  <a
                    className="text-decoration-none"
                    title="Chính sách dịch vụ"
                  >
                    Chính sách dịch vụ
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    title="Hướng dẫn đăng ký nhập học"
                  >
                    Hướng dẫn đăng ký nhập học
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    title="Hướng dẫn phụ huynh"
                  >
                    Hướng dẫn phụ huynh
                  </a>
                </li>
                <li>
                  <a
                    className="text-decoration-none"
                    title="Chính sách quy định"
                  >
                    Chính sách quy định
                  </a>
                </li>
                <li>
                  <a className="text-decoration-none" title="Chính sách tư vấn">
                    Chính sách tư vấn
                  </a>
                </li>
              </ul>
              <ul className="social social-footer list-unstyled d-flex align-items-center">
                <li className="d-inline-block align-top">
                  <a
                    href="https://www.facebook.com/mamnonkiddygarden?ref=embed_page"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-2 d-block"
                  >
                    <img
                      className="lazy rounded-circle"
                      data-src="thumbs/44x44x1/upload/photo/social05-18864.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
                <li className="d-inline-block align-top">
                  <a href="" target="_blank" className="me-2 d-block">
                    <img
                      className="lazy rounded-circle"
                      data-src="thumbs/44x44x1/upload/photo/social04-54973.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
                <li className="d-inline-block align-top">
                  <a href="" target="_blank" className="me-2 d-block">
                    <img
                      className="lazy rounded-circle"
                      data-src="thumbs/44x44x1/upload/photo/social03-75612.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
                <li className="d-inline-block align-top">
                  <a href="" target="_blank" className="me-2 d-block">
                    <img
                      className="lazy rounded-circle"
                      data-src="thumbs/44x44x1/upload/photo/social02-11051.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
                <li className="d-inline-block align-top">
                  <a
                    href="https://zalo.me/0937030108"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="me-2 d-block"
                  >
                    <img
                      className="lazy rounded-circle"
                      data-src="thumbs/44x44x1/upload/photo/social01-39830.png"
                      alt=""
                      title=""
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="footer-news">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3927.9828938562623!2d105.66350077503249!3d10.100485590010068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDA2JzAxLjgiTiAxMDXCsDM5JzU3LjkiRQ!5e0!3m2!1svi!2s!4v1725416788214!5m2!1svi!2s"
                width="100%"
                height="50%"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="footer-powered">
          <div className="row">
            <div className="footer-copyright col-lg-6">
              Designed by{" "}
              <a
                href="https://thietkeweb.katec.vn/"
                className="text-decoration-none"
                title="Nina.vn"
              >
                katec.vn
              </a>
            </div>
          </div>
        </div>
      </div>
      <div id="chi-nhanh"></div>
      <div id="messages-facebook"></div>
    </div>
  );
};

export default Footer;
