import React, { useEffect } from "react";

import Study from "../components/Study";

const StudyPage = () => {
  useEffect(() => {
    document.title = "Chương trình học";
  }, []);
  return <Study />;
};

export default StudyPage;
