import "./TrainIndex.css"; // Để thêm CSS tùy chỉnh nếu cần
import React from "react";
import TrainImage from "../assets/image/train.png";
const TrainIndex = () => {
  return (
    <div className="train-index">
      <div className="train-container">
        <img src={TrainImage} alt="Train" />
      </div>
    </div>
  );
};

export default TrainIndex;
