import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ImageTitle from "../assets/image/des_title_main.png";
import imageArrow from "../assets/image/arrows.png";
import axios from "axios";
import { toast } from "react-toastify";
const Blog = () => {
  const [posts, setPosts] = useState([]);

  const getPost = () => {
    axios
      .get("/post/list")
      .then((res) => {
        setPosts(
          res.data.posts?.filter(
            (e) => e.id != 29 && e.id != 30 && e.id != 28
          ) || []
        );
      })
      .catch((error) => {
        toast(error.response?.data?.message || "Hệ thống xảy ra lỗi", {
          type: "error",
          autoClose: 1000,
        });
      });
  };
  useEffect(() => {
    getPost();
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    rows: 2,
    autoplaySpeed: 3500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 20,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          margin: 10,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          margin: 10,
        },
      },
    ],
  };

  return (
    <div className="newsHot-index" data-aos="fade-up" data-aos-duration="1000">
      <div className="wrap-content">
        <div className="title-main">
          <span>tin tức & sự kiện</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
        </div>
        <div className="newsHot-contain">
          <Slider {...settings} className="slick-slider">
            {/* {newsData.map((item) => ( */}
            {posts.map((item) => (
              <div key={item.id} className="news-col">
                <div className="news-item">
                  <div className="news-pic">
                    <a
                      href={`tin-tuc/${item.postSlug}`}
                      className="text-decoration-none scale-img"
                    >
                      <img
                        className="w-100 image-post"
                        onError={(e) =>
                          (e.target.src = `${process.env.REACT_APP_BACKEND_URL}${item.imgURL}`)
                        }
                        src={`${process.env.REACT_APP_BACKEND_URL}${item.imgURL}`}
                        alt={item.postTitleVi}
                      />
                    </a>
                  </div>
                  <div className="news-info">
                    <h3>
                      <a
                        href={`tin-tuc/${item.postSlug}`}
                        className="text-split text-decoration-none"
                      >
                        {item.postTitleVi}
                      </a>
                    </h3>
                    <span className="text-split">{item.postDescVi}</span>
                    <div className="btn-xemthem xemthem-bg-static">
                      <a
                        href={`tin-tuc/${item.postSlug}`}
                        className="text-decoration-none spacing-letter"
                      >
                        chi tiết
                        {/* <img src={imageArrow} alt="Arrow" /> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="btn-xemthem">
            <a href="tin-tuc" className="text-decoration-none spacing-letter">
              Xem tất cả <img src={imageArrow} alt="Arrow" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
