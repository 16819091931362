import React, { useState } from "react";
import ImageTitle from "../assets/image/des_title_main.png";
import axios from "axios";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const FormComponent = () => {
  const [formData, setFormData] = useState({
    fullname: "",
    phonenumber: "",
    email: "",
    message: "",
  });
  const [isSending, setIsSending] = useState(false);

  const sendMail = (e) => {
    e.preventDefault();
    let data = {
      from: "antho@pgdomon.edu.vn", // Thay thế bằng địa chỉ email thật
      to: "antho@pgdomon.edu.vn", // Thay thế bằng địa chỉ email thật
      subject: "Liên hệ tư vấn",
      fullname: formData?.fullname || "",
      phonenumber: formData?.phonenumber || "",
      email: "antho@pgdomon.edu.vn",
      message: formData.message || "",
    };

    setIsSending(true);

    try {
      const res = axios.post(`/sendMail`, data);

      if (res.data.status) {
        toast("Đã gửi thông tin thành công, Trường sẽ liên hệ với bạn sau", {
          type: "success",
          autoClose: 3000,
        });
      } else {
        toast("Có lỗi xảy ra, vui lòng thử lại sau", {
          type: "error",
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.error(err);
      toast("Có lỗi xảy ra, vui lòng thử lại sau", {
        type: "error",
        autoClose: 2000,
      });
    } finally {
      setIsSending(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData(updatedData);
  };

  return (
    <div
      className="form-index position-relative"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div className="form-contain">
        <div className="title-main">
          <p>Liên hệ với</p>
          <span>Mầm non an thơ</span>
          <i>
            <img src={ImageTitle} alt="Title" />
          </i>
          <p className="slogan-from">
            Nhận tin tức, cập nhật mới nhất và nhiều tin tức khác mỗi tuần.
          </p>
        </div>
        <Form className=" form-newsletter" onSubmit={sendMail}>
          <div className="row">
            <div className="col-12">
              <div className="newsletter-input">
                <div className="form-floating form-floating-cus cus-newsletter">
                  <input
                    type="text"
                    className="form-control text-sm"
                    id="fullname-newsletter"
                    name="fullname"
                    placeholder="Nhập họ tên"
                    value={formData.fullname}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="fullname-newsletter">Nhập họ tên</label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="newsletter-input">
                <div className="form-floating form-floating-cus cus-newsletter">
                  <input
                    type="tel"
                    className="form-control text-sm"
                    id="phone-newsletter"
                    name="phonenumber"
                    placeholder="Nhập số điện thoại"
                    value={formData.phonenumber}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="phone-newsletter">Điện thoại</label>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="newsletter-input">
                <div className="form-floating form-floating-cus cus-newsletter">
                  <input
                    type="text"
                    className="form-control text-sm"
                    id="content-newsletter"
                    name="message"
                    placeholder="Nhập Nội dung"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <label htmlFor="content-newsletter">Nội dung</label>
                </div>
              </div>
            </div>
          </div>
          <div className="newsletter-button d-flex justify-content-center">
            <input
              type="hidden"
              name="dataNewsletter[type]"
              value="dangkynhantin"
            />
            <input
              type="hidden"
              name="dataNewsletter[date_created]"
              value="1723536723"
            />
            <input
              type="reset"
              value="Nhập lại"
              className="spacing-letter"
              onClick={() =>
                setFormData({
                  fullname: "",
                  phonenumber: "",
                  email: "",
                  message: "",
                })
              }
            />
            <input
              type="submit"
              className="spacing-letter btn btn-sm  w-100"
              name="submit-newsletter"
              value="Đăng ký"
              disabled={isSending}
              // onClick={() => sendMail()}
            />
            <input
              type="hidden"
              className="btn btn-sm btn-danger w-100"
              name="recaptcha_response_newsletter"
              id="recaptchaResponseNewsletter"
            />
          </div>
        </Form>
      </div>

      <div className="des-form--after">
        {/* <img
          alt="MẦM NON AN THƠ"
        /> */}
      </div>
    </div>
  );
};

export default FormComponent;
